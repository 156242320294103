var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { "no-shadow": "" } },
    [
      _c("vs-textarea", {
        attrs: { label: "Bio", placeholder: "Your bio..." },
        model: {
          value: _vm.bio,
          callback: function ($$v) {
            _vm.bio = $$v
          },
          expression: "bio",
        },
      }),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Birth Date")]),
          _c("flat-pickr", {
            staticClass: "w-full",
            attrs: { config: { dateFormat: "d F Y" } },
            model: {
              value: _vm.dob,
              callback: function ($$v) {
                _vm.dob = $$v
              },
              expression: "dob",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Country")]),
          _c("v-select", {
            attrs: {
              options: _vm.countryOptions,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            model: {
              value: _vm.country,
              callback: function ($$v) {
                _vm.country = $$v
              },
              expression: "country",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Languages")]),
          _c("v-select", {
            attrs: {
              multiple: "",
              closeOnSelect: false,
              options: _vm.langOptions,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            model: {
              value: _vm.lang_known,
              callback: function ($$v) {
                _vm.lang_known = $$v
              },
              expression: "lang_known",
            },
          }),
        ],
        1
      ),
      _c("vs-input", {
        staticClass: "w-full mt-8",
        attrs: { type: "number", "label-placeholder": "Mobile" },
        model: {
          value: _vm.mobile,
          callback: function ($$v) {
            _vm.mobile = $$v
          },
          expression: "mobile",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mt-8",
        attrs: { "label-placeholder": "Website" },
        model: {
          value: _vm.website,
          callback: function ($$v) {
            _vm.website = $$v
          },
          expression: "website",
        },
      }),
      _c("div", { staticClass: "mt-8 mb-base" }, [
        _c("label", { staticClass: "text-sm" }, [_vm._v("Gender")]),
        _c(
          "div",
          { staticClass: "mt-2" },
          [
            _c(
              "vs-radio",
              {
                staticClass: "mr-4",
                attrs: { "vs-value": "male" },
                model: {
                  value: _vm.gender,
                  callback: function ($$v) {
                    _vm.gender = $$v
                  },
                  expression: "gender",
                },
              },
              [_vm._v("Male")]
            ),
            _c(
              "vs-radio",
              {
                staticClass: "mr-4",
                attrs: { "vs-value": "female" },
                model: {
                  value: _vm.gender,
                  callback: function ($$v) {
                    _vm.gender = $$v
                  },
                  expression: "gender",
                },
              },
              [_vm._v("Female")]
            ),
            _c(
              "vs-radio",
              {
                attrs: { "vs-value": "other" },
                model: {
                  value: _vm.gender,
                  callback: function ($$v) {
                    _vm.gender = $$v
                  },
                  expression: "gender",
                },
              },
              [_vm._v("Other")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-end" },
        [
          _c("vs-button", { staticClass: "ml-auto mt-2" }, [
            _vm._v("Save Changes"),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "ml-4 mt-2",
              attrs: { type: "border", color: "warning" },
            },
            [_vm._v("Reset")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }