var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", { attrs: { "no-shadow": "" } }, [
    _c("div", { staticClass: "mb-base" }, [
      _c("h6", { staticClass: "mb-4" }, [_vm._v("Activity")]),
      _c(
        "div",
        { staticClass: "flex items-center mb-4" },
        [
          _c("vs-switch", {
            model: {
              value: _vm.comment,
              callback: function ($$v) {
                _vm.comment = $$v
              },
              expression: "comment",
            },
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v("Email me when someone comments onmy article"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex items-center mb-4" },
        [
          _c("vs-switch", {
            model: {
              value: _vm.answer,
              callback: function ($$v) {
                _vm.answer = $$v
              },
              expression: "answer",
            },
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v("Email me when someone answers on my form"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex items-center mb-4" },
        [
          _c("vs-switch", {
            model: {
              value: _vm.follow,
              callback: function ($$v) {
                _vm.follow = $$v
              },
              expression: "follow",
            },
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v("Email me hen someone follows me"),
          ]),
        ],
        1
      ),
    ]),
    _c("div", [
      _c("h6", { staticClass: "mb-4" }, [_vm._v("Application")]),
      _c(
        "div",
        { staticClass: "flex items-center mb-4" },
        [
          _c("vs-switch", {
            model: {
              value: _vm.news,
              callback: function ($$v) {
                _vm.news = $$v
              },
              expression: "news",
            },
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v("News and announcements"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex items-center mb-4" },
        [
          _c("vs-switch", {
            model: {
              value: _vm.product_update,
              callback: function ($$v) {
                _vm.product_update = $$v
              },
              expression: "product_update",
            },
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v("Weekly product updates"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _c("vs-switch", {
            model: {
              value: _vm.blog,
              callback: function ($$v) {
                _vm.blog = $$v
              },
              expression: "blog",
            },
          }),
          _c("span", { staticClass: "ml-4" }, [_vm._v("Weekly blog digest")]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "flex flex-wrap items-center justify-end mt-base" },
      [
        _c("vs-button", { staticClass: "ml-auto mt-2" }, [
          _vm._v("Save Changes"),
        ]),
        _c(
          "vs-button",
          {
            staticClass: "ml-4 mt-2",
            attrs: { type: "border", color: "warning" },
          },
          [_vm._v("Reset")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }