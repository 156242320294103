var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { "no-shadow": "" } },
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center mb-base" },
        [
          _c("vs-avatar", {
            staticClass: "mr-4 mb-4",
            attrs: { src: _vm.activeUserInfo.photoURL, size: "70px" },
          }),
          _c(
            "div",
            [
              _c("vs-button", { staticClass: "mr-4 sm:mb-0 mb-2" }, [
                _vm._v("Upload photo"),
              ]),
              _c("vs-button", { attrs: { type: "border", color: "danger" } }, [
                _vm._v("Remove"),
              ]),
              _c("p", { staticClass: "text-sm mt-2" }, [
                _vm._v("Allowed JPG, GIF or PNG. Max size of 800kB"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: { "label-placeholder": "Username" },
        model: {
          value: _vm.username,
          callback: function ($$v) {
            _vm.username = $$v
          },
          expression: "username",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: { "label-placeholder": "Name" },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full",
        attrs: { "label-placeholder": "Email" },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _c(
        "vs-alert",
        {
          staticClass: "h-full my-4",
          attrs: {
            "icon-pack": "feather",
            icon: "icon-info",
            color: "warning",
          },
        },
        [
          _c("span", [
            _vm._v("Your account is not verified. "),
            _c("a", { staticClass: "hover:underline", attrs: { href: "#" } }, [
              _vm._v("Resend Confirmation"),
            ]),
          ]),
        ]
      ),
      _c("vs-input", {
        staticClass: "w-full my-base",
        attrs: { "label-placeholder": "Company" },
        model: {
          value: _vm.company,
          callback: function ($$v) {
            _vm.company = $$v
          },
          expression: "company",
        },
      }),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-end" },
        [
          _c("vs-button", { staticClass: "ml-auto mt-2" }, [
            _vm._v("Save Changes"),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "ml-4 mt-2",
              attrs: { type: "border", color: "warning" },
            },
            [_vm._v("Reset")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }