var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-tabs",
    {
      key: _vm.isSmallerScreen,
      staticClass: "tabs-shadow-none",
      attrs: {
        position: _vm.isSmallerScreen ? "top" : "left",
        id: "profile-tabs",
      },
    },
    [
      _c(
        "vs-tab",
        {
          attrs: {
            "icon-pack": "feather",
            icon: "icon-user",
            label: !_vm.isSmallerScreen ? "General" : "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "tab-general md:ml-4 md:mt-0 mt-4 ml-0" },
            [_c("user-settings-general")],
            1
          ),
        ]
      ),
      _c(
        "vs-tab",
        {
          attrs: {
            "icon-pack": "feather",
            icon: "icon-lock",
            label: !_vm.isSmallerScreen ? "Change Password" : "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0" },
            [_c("user-settings-change-password")],
            1
          ),
        ]
      ),
      _c(
        "vs-tab",
        {
          attrs: {
            "icon-pack": "feather",
            icon: "icon-info",
            label: !_vm.isSmallerScreen ? "Info" : "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "tab-info md:ml-4 md:mt-0 mt-4 ml-0" },
            [_c("user-settings-info")],
            1
          ),
        ]
      ),
      _c(
        "vs-tab",
        {
          attrs: {
            "icon-pack": "feather",
            icon: "icon-github",
            label: !_vm.isSmallerScreen ? "Social Links" : "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "tab-social-links md:ml-4 md:mt-0 mt-4 ml-0" },
            [_c("user-settings-social-links")],
            1
          ),
        ]
      ),
      _c(
        "vs-tab",
        {
          attrs: {
            "icon-pack": "feather",
            icon: "icon-link-2",
            label: !_vm.isSmallerScreen ? "Connections" : "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "tab-text md:ml-4 md:mt-0 mt-4 ml-0" },
            [_c("user-settings-connections")],
            1
          ),
        ]
      ),
      _c(
        "vs-tab",
        {
          attrs: {
            "icon-pack": "feather",
            icon: "icon-bell",
            label: !_vm.isSmallerScreen ? "Notifications" : "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "tab-text md:ml-4 md:mt-0 mt-4 ml-0" },
            [_c("user-settings-notifications")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }