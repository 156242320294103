var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { "no-shadow": "" } },
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-between items-center mb-8" },
        [
          _c("div", [
            _c("p", [_vm._v("Account is connected with Google.")]),
            _c("p", { staticClass: "font-medium mb-4" }, [
              _vm._v("john@gmail.com"),
            ]),
          ]),
          _c("vs-button", { attrs: { type: "border", color: "danger" } }, [
            _vm._v("Disconnect"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-between items-center mb-base" },
        [
          _c("div", [
            _c("p", [_vm._v("Account is connected with facebook.")]),
            _c("p", { staticClass: "font-medium mb-4" }, [
              _vm._v("@pixinvents"),
            ]),
          ]),
          _c("vs-button", { attrs: { type: "border", color: "danger" } }, [
            _vm._v("Disconnect"),
          ]),
        ],
        1
      ),
      _c(
        "vs-button",
        {
          staticClass: "mb-6 ml-auto",
          attrs: {
            color: "#00aaff",
            "icon-pack": "feather",
            icon: "icon-twitter",
          },
        },
        [_vm._v("Connected To Twitter")]
      ),
      _c(
        "vs-button",
        {
          staticClass: "ml-auto",
          attrs: {
            color: "#405DE6",
            "icon-pack": "feather",
            icon: "icon-instagram",
          },
        },
        [_vm._v("Connected To Instagram")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }