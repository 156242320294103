import { render, staticRenderFns } from "./UserSettingsChangePassword.vue?vue&type=template&id=04c8f1b0&"
import script from "./UserSettingsChangePassword.vue?vue&type=script&lang=js&"
export * from "./UserSettingsChangePassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04c8f1b0')) {
      api.createRecord('04c8f1b0', component.options)
    } else {
      api.reload('04c8f1b0', component.options)
    }
    module.hot.accept("./UserSettingsChangePassword.vue?vue&type=template&id=04c8f1b0&", function () {
      api.rerender('04c8f1b0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/user-settings/UserSettingsChangePassword.vue"
export default component.exports