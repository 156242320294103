var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { "no-shadow": "" } },
    [
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: { "label-placeholder": "Old Password" },
        model: {
          value: _vm.old_password,
          callback: function ($$v) {
            _vm.old_password = $$v
          },
          expression: "old_password",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: { "label-placeholder": "New Password" },
        model: {
          value: _vm.new_password,
          callback: function ($$v) {
            _vm.new_password = $$v
          },
          expression: "new_password",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: { "label-placeholder": "Confirm Password" },
        model: {
          value: _vm.confirm_new_password,
          callback: function ($$v) {
            _vm.confirm_new_password = $$v
          },
          expression: "confirm_new_password",
        },
      }),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-end" },
        [
          _c("vs-button", { staticClass: "ml-auto mt-2" }, [
            _vm._v("Save Changes"),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "ml-4 mt-2",
              attrs: { type: "border", color: "warning" },
            },
            [_vm._v("Reset")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }