var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { "no-shadow": "" } },
    [
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: {
          "icon-pack": "feather",
          icon: "icon-twitter",
          label: "GitHub",
          "icon-no-border": "",
        },
        model: {
          value: _vm.twitter,
          callback: function ($$v) {
            _vm.twitter = $$v
          },
          expression: "twitter",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: {
          "icon-pack": "feather",
          icon: "icon-facebook",
          label: "GitHub",
          "icon-no-border": "",
        },
        model: {
          value: _vm.facebook,
          callback: function ($$v) {
            _vm.facebook = $$v
          },
          expression: "facebook",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: {
          "icon-pack": "feather",
          icon: "icon-instagram",
          label: "GitHub",
          "icon-no-border": "",
        },
        model: {
          value: _vm.instagram,
          callback: function ($$v) {
            _vm.instagram = $$v
          },
          expression: "instagram",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: {
          "icon-pack": "feather",
          icon: "icon-github",
          label: "GitHub",
          "icon-no-border": "",
        },
        model: {
          value: _vm.github,
          callback: function ($$v) {
            _vm.github = $$v
          },
          expression: "github",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: {
          "icon-pack": "feather",
          icon: "icon-linkedin",
          label: "GitHub",
          "icon-no-border": "",
        },
        model: {
          value: _vm.linkedin,
          callback: function ($$v) {
            _vm.linkedin = $$v
          },
          expression: "linkedin",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: {
          "icon-pack": "feather",
          icon: "icon-slack",
          label: "GitHub",
          "icon-no-border": "",
        },
        model: {
          value: _vm.slack,
          callback: function ($$v) {
            _vm.slack = $$v
          },
          expression: "slack",
        },
      }),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-end" },
        [
          _c("vs-button", { staticClass: "ml-auto mt-2" }, [
            _vm._v("Save Changes"),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "ml-4 mt-2",
              attrs: { type: "border", color: "warning" },
            },
            [_vm._v("Reset")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }